import React from 'react';
import ReactDOM from 'react-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FaMagic } from "react-icons/fa";
import {motion} from 'framer-motion'
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate, useLocation } from 'react-router-dom'
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#00a2ff'
        },
      secondary: {
        main: '#E33E7F'
      }
    }
  });

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
    firstName: yup.string("Enter your first name").required("First name is required"),
    lastName: yup.string("Enter your last name").required("Last name is required"),
    confirmPassword: yup.string("Enter your password").required("Password Does not match").oneOf([yup.ref("password")], "Password does not match")
});

const WithMaterialUI = () => {
  const formik = useFormik({
    initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
const navigate = useNavigate();

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%'}}>
    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', marginTop: 50,}}>
    <FaMagic size={35}/>
    <h1 style={{color:'white', fontSize: 40, fontWeight:'600',  marginLeft: 20}}>AIMG</h1>
    </div>
    <div style={{width: '50%'}}>
    <form onSubmit={formik.handleSubmit}>
    <ThemeProvider theme={theme}>
    <div style={{display:'flex', flexDirection:'row'}}>
    <TextField
          fullWidth
          id="firstName"
          name="firstName"
          label="First Name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          InputLabelProps={{style: { color: '#fff' }}}
          InputProps={{ inputProps: { style: { color: '#fff' }}}}
          style={{marginTop: 20, marginBottom:10}}
        />
        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last Name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          InputLabelProps={{style: { color: '#fff' }}}
          InputProps={{ inputProps: { style: { color: '#fff' }}}}
          style={{marginTop: 20, marginBottom:10, marginLeft: 20}}
        />
    </div>
    
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputLabelProps={{style: { color: '#fff' }}}
          InputProps={{ inputProps: { style: { color: '#fff' }}}}
          style={{marginTop: 20, marginBottom:10}}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputLabelProps={{style: { color: '#fff' }}}
          InputProps={{ inputProps: { style: { color: '#fff' }}}}
          style={{marginTop: 20, marginBottom:20}}
          
        />
        <TextField
          fullWidth
          id="confirmPassword"
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          InputLabelProps={{style: { color: '#fff' }}}
          InputProps={{ inputProps: { style: { color: '#fff' }}}}
          style={{marginTop: 20, marginBottom: 50}}
        />
        
        <Button style={{fontWeight:"600", background:'#00a2ff'}} color="primary" variant="contained" fullWidth type="submit">
          Sign up
        </Button>
        </ThemeProvider>
      </form>
    </div>
    <motion.div onTap={()=> navigate('/')} whileTap={{scale: 0.9}} style={{position:'absolute', left: 30, top: 30 }}  >
            <IoIosArrowDropleftCircle size={50}/>
    </motion.div>
    </div>
  );
};

export default WithMaterialUI;