import React from 'react'
import { motion } from 'framer-motion'
import { BsArrowClockwise } from "react-icons/bs";
import {Watermark} from 'antd' 


export default function Images({AllImages, SelectImage, ChangeSelection, handleClick}) {
  return (
    <div style={{display:"flex", flexDirection:'column',  marginLeft: 35, marginRight: 20}}>
        <p style={{fontSize: 30, fontWeight:'500'}}>Masterpiece</p>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', flexWrap:"wrap"}}>
        <div style={{ margin: 17, width: '80%' }}>
        <Watermark content={"AIMG"} gap={[50, 50]} offset={[10, 10]} font={{ fontSize: 30}} >
        <img src={SelectImage}  style={{width:"100%", borderRadius: 10}}/> 
        </Watermark>
        </div>
       
        
        
            <motion.div onTap={handleClick} whileTap={{scale: 0.9}} style={{background:'#eeee', color:"black", display:'flex', fontWeight:'600', borderRadius: 10, padding: 10, alignItems:'center', width:'80%', flexDirection:'row', fontSize: 25, justifyContent:'space-evenly'}}>
              Reload New Image
                <BsArrowClockwise size={40} color='black'/>
            </motion.div>
        </div>
    </div>
  )
}
