import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar';
import MetalFramed from '../images/MetalFramed.png'
import WoodenFramed from '../images/WoodenFramed.png'
import Canvas from '../images/CanvasExample.png'
import { motion } from 'framer-motion';
import Poster from '../images/PaintingExample.png'

const products = [
    {
        name: "Poster",
        image: Poster,
        url: "/create?style=poster",
        Price: 8.99

    },
//     {
//     name: "Wooden Framed",
//     image: WoodenFramed,
//     url: "/create?style=wooden+framed",
//     Price: 24.99
// }, 
// {
//     name: "Metal Framed",
//     image: MetalFramed,
//     url: "/create?style=metal+framed",
//     Price: 24.99
// },
{
    name: "Framed",
    image: Canvas,
    url: "/create?style=canvas",
    Price: 14.99
}
]

export default function Products() {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
    const navigate = useNavigate();
    return (
        <div >
            <Navbar isMobile={isMobile} />
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:'center', flexWrap:'wrap', height:"70vh"}}>
            {products.map(item=>{
                return(<motion.div whileHover={{scale: 1.1}} whileTap={{scale: 0.9}} onTap={()=> navigate(item.url)} style={{display:"flex", flexDirection:'column', justifyContent:'space-evenly',backgroundColor:'transparent', borderRadius: 10, width:300, height: 400, margin: 30, borderWidth:3, borderStyle:'solid', alignItems:'center'}}>
                    <img src={item.image} style={{height: 200, width:180, margin: 15}}  />
                    <div>
                        <p style={{fontSize: 30, textAlign:'center', color:'white', fontWeight:'700'}}> {item.name}</p>
                        <p style={{fontSize: 20, textAlign:'center', color:'white', fontWeight:'600', opacity: 0.8}}>Starting at {item.Price}$</p>
                    </div>
                </motion.div>)
            })}
            </div>
        </div>
    )
}