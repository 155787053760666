import React, { useEffect, useState } from 'react'
import Typewriter from 'typewriter-effect';
import { motion, useScroll } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { FaChartBar, FaCode, FaFileAlt, FaPhotoVideo, FaUserTie } from "react-icons/fa";
import Navbar from '../components/Navbar';
import { Parallax } from 'react-scroll-parallax';
import Examples from '../components/home/Examples';
import HowTo from '../components/home/HowTo';
import { useInView } from 'react-intersection-observer';
import ModernHowTo from '../components/home/ModernHowTo';
import Paris from '../images/Paris.png'
import SlidingPane from 'react-sliding-pane';
import MenuProvider from 'react-flexible-sliding-menu'
import Footer from '../components/home/Footer';


export default function Home() {
    const [side, setSide] = useState(false)
    const navigate = useNavigate();
    const [ref, inView] = useInView({
        triggerOnce: true,
      });
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 2 } },
    };

      

    return (
        <div  >
            <Navbar isMobile={isMobile} side={side} setSide={setSide}/>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 50, paddingBottom: 50, flexDirection:'column', alignItems:'center' }}>
            <Parallax  style={{display:"flex", flexDirection:'column', alignItems:'center'}} speed={-10} >
                        <h1 className='text' style={{fontSize: isMobile? 50: 60, textAlign:'center', padding: 20}}>Transforming AI into Canvas Masterpieces</h1>
                        <p style={{ opacity: 0.8, fontSize: 20}}>Print Your Vision on Canvas Today!</p>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} onTap={()=> navigate('/create')} style={{ fontSize: 35, padding: 10, backgroundColor: "white", borderRadius: 10, borderColor: 'white', fontWeight: '500', background: "linear-gradient(250deg, #51D6FF, #cd00ff)", color: 'white', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, margin: 10, marginBottom: 70, fontWeight: '600' }}>Start Painting</motion.div>
            <img src={Paris} style={{width:'70%', borderRadius: 10, marginBottom: 100}}/>
            </Parallax>
                        <motion.div
                        ref={ref}
                    initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={variants}
    >
                        <ModernHowTo isMobile={isMobile} navigate={navigate}/>
                        </motion.div>
        <motion.span onTap={()=>  navigate('/create')} whileHover={{scale: 1.1}} whileTap={{scale: 0.9}} style={{fontSize: 35, background:'#f3f3f3', padding: 10, borderRadius: 20, fontWeight:'500', margin: 50, paddingRight:20, paddingLeft: 20, color:'black'}}>
             Try it Now
        </motion.span>
        </div>
        <Footer/>
        </div>
    )
}
