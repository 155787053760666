import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/home/Footer';

export default function Shipping() {
    const isMobile = window.innerWidth <= 768;
    const style = {
        color: 'white',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      };
  return (
    <div>
        <Navbar isMobile={isMobile}/>
        <div style={style}>
      <h1 style={{display:'flex', justifyContent:'center'}}>Refund Policy</h1>

      <p>We are committed to our customers! If you didn’t like your printed piece, we will give you a full refund of the purchase price! At AIMG.ART, we want you to be satisfied with the art you create and purchase.</p>

      <p>Any customer is qualified for a refund as long as they send it back to us within 30 days of purchasing the art. We don’t want you to incur any risk by using our website! &lt;3</p>
    </div>
    <Footer/>
    </div>
  )
}
