import React from 'react'
import { motion } from 'framer-motion'

const styles =["None", "Van Gogh", "Vibrant", "Minimalist", "Neon", "Filmic", "Colorful", "3D", "Retrowave", "Psychedelic", "Concept art", "Gradient", "Dreamlike", "Anime", "Watercolor", "Color pencil", "Stained glass", "Ink print"]


export default function Style({ChangeStyle, StyleSelected}) {
  return (
    <div  style={{display:"flex", flexDirection:'column', paddingTop: 40, paddingLeft:40, paddingRight: 40, width:"100%", fontSize: 30, fontWeight:'500' }}>
        Style
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', overflowX:'auto', overflow:'scroll', marginRight:50, marginTop: 10}}>
        {styles.map(item => {
            return (<motion.div onTap={()=> {
                ChangeStyle(item)
            }} whileTap={{scale: 0.9}} style={{padding: 10, borderStyle:'solid', borderWidth: 2,fontSize: 25, fontWeight:'500', color: StyleSelected == item? "#3c95ff" : 'white', borderColor:StyleSelected == item? "#3c95ff" : 'white', borderRadius: 10, margin:10, flexWrap:'nowrap', display:'flex', flexDirection:'row'}}>
                    
                  <div style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                  {item}
                  </div>
                   
                </motion.div>)
        })}
        </div>
        
    </div>
  )
}
