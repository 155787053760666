import React from 'react'
import {motion} from 'framer-motion'
import { format } from 'date-fns';

export default function Gallery({Painting, navigate}) {
 
  return (
    <div>
        <p style={{fontSize: 35, fontWeight:'600', marginLeft: 50}}>Gallery</p>
        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', paddingBottom: 70}}>
        {Painting.map(item=>
            <motion.div onTap={()=> navigate('/market/painting')} whileTap={{scale: 0.9}} style={{display:'flex', flexDirection:'column', alignItems:'center', marginLeft: 50, marginBottom: 50, alignItems:'center', background:'#4e5666', padding: 20, borderRadius: 10}}>
            <img style={{height: 275, width: 275, borderRadius: 10}} src={item.image}/>
            <p style={{fontSize: 25, fontWeight:'600'}}>{item.name}</p>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'90%'}}>
            <img src={item.artist.image} style={{height: 50, width: 50, borderRadius: 50}}/>
            <div style={{display:'flex', flexDirection:'column', marginLeft: 20,  justifyContent:'space-around'}}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <div style={{fontSize: 12, fontWeight:'500', opacity: 0.9}}>By</div>
            <div style={{fontSize: 13, fontWeight:'600', opacity: 0.9, marginLeft: 6}}>{item.artist.name}</div>
            </div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop: 5}}>
            <div style={{fontSize: 12, fontWeight:'500', opacity: 0.9}}>Created in</div>
            <div style={{fontSize: 13, fontWeight:'600', opacity: 0.9, marginLeft: 6}}>{format(item.date, 'MMM dd yyyy')}</div>
            </div>
            </div>
            </div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', width: '90%'}}>
            <p style={{fontSize: 25, fontWeight:'600', opacity: 0.9}}>${item.price}</p>
            <motion.div whileTap={{scale: 0.9}} style={{backgroundColor: '#7000FF', fontSize: 20, borderRadius: 10, height: 40, width: 70, fontWeight:'600', alignItems:'center', justifyContent:'center', display:'flex'}}>
                <p>Buy</p>
            </motion.div>
            </div>
            </motion.div>
            )}
     </div>
    </div>
  )
}
