import React from 'react'
import { motion } from 'framer-motion'

export default function search({ChangeSearch, handleClick, SearchWord, loading}) {
  return (
    <div style={{display:"flex", flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginLeft: 30, marginRight: 30 }}>
        <motion.div style={{ minHeight: 55, width:'70%', background: 'white', borderRadius: 10, display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                <input type="text" onChange={ChangeSearch} placeholder='Imagine' style={{ fontSize: 20, width: '80%', borderWidth: 0, marginLeft: 20}} />
        </motion.div>
       
          <motion.div initial={{opacity:0}} animate={{opacity: SearchWord? 1 : 0.5}} whileHover={{ scale: 1.1 }} onTap={(!loading & SearchWord.length !=0 )? ()=> handleClick(): null} whileTap={{ scale: 0.9 }} style={{ fontSize: 20, padding: 10, backgroundColor: "white", borderRadius: 10, borderColor: 'white', fontWeight: '500', background: "linear-gradient(250deg, #51D6FF, #cd00ff)", color: 'white', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, fontWeight: '600', marginLeft: 20 }}>Create</motion.div>
      
        </div>
  )
}
