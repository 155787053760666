import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';
import AnimatedPages from './routers/AnimateRouter';
import { ParallaxProvider } from 'react-scroll-parallax';
import MenuProvider from 'react-flexible-sliding-menu'
import Menu from './components/Menu'

function App() {
  return (
    <Router>
    <MenuProvider MenuComponent={Menu} direction="right">
    <ParallaxProvider>
      < AnimatedPages />
    </ParallaxProvider>
    </MenuProvider>
    </Router>
  );
}

export default App;