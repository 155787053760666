import React, {textarea, useEffect, useState} from 'react'
import Navbar from '../components/Navbar'
import { motion, useScroll } from 'framer-motion'
import client from '../api/client';
import Footer from '../components/home/Footer';

export default function Contact() {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    const [FullName, setFullName] = useState("")
    const [Email, setEmail] = useState("")
    const [Description, setDescription] = useState("")

    useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
    

    const handleNameChange = (event) => {
        // 👇 Get input value from "event" in the search bar
        setFullName(event.target.value);
    };
    const handleEmailChange = (event) => {
        // 👇 Get input value from "event" in the search bar
        setEmail(event.target.value);
    };
    const handleDescriptionChange = (event) => {
        // 👇 Get input value from "event" in the search bar
        setDescription(event.target.value);
    };

    const handleSubmit = async () => {
        await client.post('/contact', {
            FullName,
            Email,
            Description
        })
        setFullName("");
        setEmail("")
        setDescription("");
        // console.log(FullName, Email, Description)
    }

    
  return (
    <div >
        <Navbar isMobile={isMobile}/>
        <p style={{fontSize: 45, fontWeight:'700', marginLeft: 100}}>Contact </p>
        <div style={{display:'flex', flexDirection:'column', paddingLeft: 100, paddingRight: 100, alignItems:'center'}}>
        <textarea value={Description} onChange={handleDescriptionChange} placeholder='Description' style={{background:'transparent', borderColor:'transparent', color:'white', textAlign:'start', fontSize: 20, height:150, padding: 10, borderColor:'white', borderRadius: 10, width:'98%', minWidth: 250}}/>
        <div style={{ display:'flex', flexDirection:'row', marginTop: 20, justifyContent:'space-between', flexWrap:'wrap'}}>
        <textarea value={FullName} onChange={handleNameChange} placeholder='Full Name' style={{background:'transparent', borderColor:'transparent', width:'100%', minWidth: 200, color:'white', textAlign:'start', fontSize: 20, height:50, borderWidth: 1, borderColor:'white', borderRadius: 10,  padding: 10, marginTop: 20}}/>
        <textarea value={Email} onChange={handleEmailChange} placeholder='Email' style={{background:'transparent', borderColor:'transparent', width:'100%',minWidth: 200, color:'white', textAlign:'start', fontSize: 20, height:50, borderWidth: 1,  borderColor:'white', borderRadius: 10, padding: 10, marginTop: 20}}/>
        </div>
        <div style={{display:"flex", justifyContent:'center'}}>
        <motion.div onTap={(FullName.length != 0 & Email.length !=0) & Description.length != 0 ? () => handleSubmit() : null } whileHover={{scale: 1.1}} whileTap={{scale:0.9}} style={{ borderRadius: 10, background:'white', display:'flex', alignItems:'center', justifyContent:'center', height: 50, fontSize: 30, fontWeight:'500', marginTop: 30, color:'#282c34', paddingRight: 10, paddingLeft: 10, marginBottom: 20}}>
            Send
        </motion.div>
        </div>
        <p style={{marginBottom:50}}> Or contact us directly by sending an email to: aimghelp@gmail.com</p>
        </div>
       <Footer/>
    </div>
  )
}
