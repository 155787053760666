import React, { useContext } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from 'react-router-dom'

function Menu() {
  const { closeMenu } = useContext(MenuContext);
  const navigate = useNavigate()
 
  return (
    <div style={{background: '#4e5666', color:'white', height:'100%', padding: 18}}>
        <motion.div whileHover={{scale: 1.1}} whileTap={{scale:0.9}} style={{marginLeft: 15}}  onClick={closeMenu}>
        <AiOutlineClose size={35}/>
      </motion.div>
      <div style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}>
      <motion.div onTap={()=> {
        navigate('/create')
        closeMenu()
        }} whileHover={{scale: 1.1}} whileTap={{scale:0.9}}  style={{fontSize: 30, fontWeight:'600', margin: 15, marginTop: 15}}>
       Create
      </motion.div >
      <motion.div onTap={()=> {
        closeMenu()
        navigate('/contact')}}  whileHover={{scale: 1.1}} whileTap={{scale:0.9}} style={{fontSize: 30, fontWeight:'600',margin: 15}}>
       Contact
      </motion.div>
      <motion.div  onTap={()=> {
        closeMenu()
        navigate('/about')}}  whileHover={{scale: 1.1}} whileTap={{scale:0.9}} style={{fontSize: 30, fontWeight:'600',margin: 15}}>
       About
      </motion.div>
      </div>
      
    </div>
  );
}

export default Menu;