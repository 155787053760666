import React from 'react';
import PaintingStand from '../../images/PaintingStand.png'
import Typewriter from 'typewriter-effect';
import { motion } from 'framer-motion';
import BabyYoda from '../../images/BabyYoda.png'
import Luigi from '../../images/Trump.png'
import Shrek from '../../images/Shrek.png'
import Trump from '../../images/TrumpBodyBuilder.png'
import Select from 'react-select';
import { AiOutlinePlusCircle} from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";


const only3 = [
    { value:  "30x30" , label: "30x30 cm / 12x12″ Small", bgcolor: "#498205", color: "#ffA" },
    { value:  "50x50" , label: "50x50 cm / 20x20″ Medium", bgcolor: "#c19c00", color: "#Aff" },
    { value:  "70x70" , label: "70x70 cm / 28x28″ Large", bgcolor: "#c19c00", color: "#Aff" },
  ];



const styles = {
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isSelected ? "bold" : "normal",
          color: state.isSelected ? "white" : "black",
          fontSize: state.selectProps.myFontSize
        })
    };

const images = [BabyYoda, Luigi, Shrek, Trump]
export default function ModernHowTo({isMobile, navigate}) {
  return (
    <div style={{ color:'#282c34', width:'100vw', background:'#f3f3f3', borderRadius:30, paddingBottom: 50, height:'100%'}}>
        <p style={{textAlign:'center', fontSize: 70, fontWeight:'600', paddingTop: 40}}>How it Works?</p>
        <p style={{fontSize: isMobile? 40 : 50, fontWeight:'600', marginLeft:50, marginRight: 50}}>
            Step 1: Create Image 
        </p>
    <div style={{display:'flex', flexDirection:'row', alignItems:'center', flexWrap:'wrap', margin: 50, justifyContent:'center'}}>
    <div style={{background:'white', color:'black', fontSize: 25, borderRadius: 10, height: 60, width: '100%',  display:'flex', alignItems:'center', paddingLeft: 20, boxShadow: "2px 2px 2px #9E9E9E"}}>
      <Typewriter
         options={{
            strings: ['Panda Astronaut', 'Elon Musk as a Roman Warrior', 'Tiger fighting a Grizzly Bear'],
            autoStart: true,
            loop: true,
        }}
        />
      </div>
      <motion.div onTap={()=>  navigate('/create')} initial={{opacity:0}} animate={{opacity: 1}} whileTap={{ scale: 0.9 }} style={{ fontSize: 20, padding: 10, backgroundColor: "white", borderRadius: 10, borderColor: 'white', fontWeight: '500', background: "linear-gradient(250deg, #51D6FF, #cd00ff)", color: 'white', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, fontWeight: '600', marginLeft: 20, display:'flex', alignItems:'center', height: 30, marginRight:50, boxShadow: "2px 2px 2px #9E9E9E", marginTop: 30}}>Create</motion.div>
    </div>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', overflowX:'auto', marginLeft: 50, justifyContent: isMobile? 'null': 'space-evenly'}}>
            {images.map((item, index)=> {
                return (
                    <motion.div  whileTap={{scale: 0.9}} >
                <img src={item} style={{borderRadius: 20, height: 250, width: 250, margin: 17}}/> </motion.div>
                )
            })}
        </div>
        <p style={{fontSize: isMobile? 40 : 50, fontWeight:'600', marginLeft: 50,  marginRight: 20}}>
            Step 2: Select Size
        </p>
        <div style={{margin: 50}}>
        <Select
        options={only3} styles={styles} />
        </div>
        <p style={{fontSize: isMobile? 40 : 50, fontWeight:'600', marginLeft: 50, marginRight: 20}}>
            Step 3: Select Quantity
        </p>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', fontWeight:'600', justifyContent:"center", fontSize: isMobile? 50: 70, marign: 70}}>
        
            <motion.div  style={{display:'flex', marginRight: 15}} whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
             <AiOutlineMinusCircle size={45} color={"black"}/>
             </motion.div>
            1
            <motion.div  style={{display:'flex', marginLeft: 15}}  whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
            <AiOutlinePlusCircle size={45} />
            </motion.div>
        </div>
        </div>
  )
}
