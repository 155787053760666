import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import { motion } from 'framer-motion'
import { FaMagic } from "react-icons/fa";
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import { FiMenu } from "react-icons/fi";
import SlidingPane from 'react-sliding-pane';
import { MenuContext } from "react-flexible-sliding-menu";
import { BiPlus } from "react-icons/bi";
import { AiOutlineDown } from "react-icons/ai";


function Navbar({isMobile}) {
    const navigate = useNavigate();
    const { toggleMenu } = useContext(MenuContext);
    const [state, setState] = useState(false)

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 50, paddingLeft: 50, paddingTop: 30 }}>

            <motion.div whileHover={{scale: 1.1}} whileTap={{scale: 0.9}} onClick={() => navigate('/')} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {/* <img src={bird} width={50} height={50} /> */}
                <FaMagic size={25} />
                <p style={{ fontSize: 25, marginLeft: 20 }}>AIMG</p>
            </motion.div>
            {isMobile? ( <motion.div onTap={toggleMenu} whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
            <FiMenu size={35}/>
            </motion.div>) : (<>
                {/* <motion.div onClick={() => navigate('/market')} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} style={{ fontSize: 25, padding: 10, borderRadius: 10, paddingRight:15, paddingLeft: 15, fontWeight: '500', color: 'white', }}>Market</motion.div> */}
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <motion.div onClick={() => navigate('/create')} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} style={{ fontSize: 25, marginRight: 20, padding: 10, paddingRight:15, paddingLeft: 15, borderRadius: 10, fontWeight: '500', color: 'white', background:'#4e5666', display:'flex', alignItems:'center' }}>Create
                <BiPlus size={30} style={{marginLeft: 10}}/>
                </motion.div>
            <div>
            <motion.div onClick={() => setState(!state)} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} style={{ fontSize: 25, marginLeft: 10, padding: 10, borderRadius: 10, paddingRight:15, paddingLeft: 15, fontWeight: '500', color: 'white', display:'flex', alignItems:'center' }}>Company
            <motion.div animate={{rotate: state? 180: 0}} style={{display:'flex', alignItems:'center', justifyContent:'center', marginLeft: 10}}>
            <AiOutlineDown size={25} />
            </motion.div>
            </motion.div> 
            {state && <motion.div  style={{display:'flex', flexDirection:'column', background:'#4e5666', borderRadius: 10, padding: 10, position:'absolute', zIndex: 100, marginTop: 10}}> 
            <motion.div onClick={() => navigate('/contact')} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} style={{ fontSize: 25, padding: 10, borderRadius: 10, paddingRight:15, paddingLeft: 15, fontWeight: '500', color: 'white', }}>Contact</motion.div>
            <motion.div onClick={() => navigate('/about')} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} style={{ fontSize: 25, padding: 10, borderRadius: 10, paddingRight:15, paddingLeft: 15, fontWeight: '500', color: 'white', }}>About</motion.div>
            </motion.div>}
            </div>
            </div>
            {/* <div style={{ display: 'flex' }}>
                <motion.div onClick={() => navigate('/login')} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} style={{ fontSize: 25, padding: 10, borderRadius: 10, marginRight: 20, borderWidth: 1, borderColor: 'white', fontWeight: '500', color: 'white', borderStyle: 'solid', }}>Log in</motion.div>
                <motion.div onClick={() => navigate('/signup')} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} style={{ fontSize: 25, padding: 10, backgroundColor: "white", borderRadius: 10, fontWeight: '500', background: 'white', color: '#282c34', fontWeight: '600' }}>Sign up</motion.div>
            </div> */}
            </>)}
    
        </div >
    )
}

export default Navbar