import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { motion, useScroll } from 'framer-motion'
import David from '../images/David.jpeg'
import Patricio from '../images/Patricio.jpg'
import SteveJobs from '../images/SteveJobs.jpeg'
import Rome from '../images/Rome.jpeg'
import Footer from '../components/home/Footer'

export default function About() {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
  return (
    <div>
      <Navbar isMobile={isMobile}/>
      <div style={{ padding: 40}}>
      <p className='text' style={{fontSize: isMobile? 40 : 60, fontWeight: '600', textAlign:'center', padding: 20}}>
          "Our mission is to bridge the gap between technology and imagination"
        </p>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:"center", flexWrap:'wrap'}}>
        <div style={{margin: 20, width:'50%', minWidth: 350}}>    
        <p style={{fontSize:  isMobile? 20:  25, fontWeight: '600'}}>
          Founded on a shared passion for both art and innovation, AIMG brings together a diverse team of artists, engineers, and visionaries. With a firm belief that everyone is an artist at heart, we've created a platform that transforms your ideas into captivating artworks.
        </p>
        <p style={{fontSize:  isMobile? 20: 25, fontWeight: '600'}}> 
          How does it work? It's simple. Just provide us with a prompt, a snippet of your vision, and our advanced AI algorithms take the reins. The AI's creative prowess turns your prompt into a unique and visually striking painting, capturing the essence of your idea in a way that's nothing short of mesmerizing.
        </p>
            </div>
        <img src={SteveJobs} style={{height: 350, width:350, margin: 10}}/>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:"center", flexWrap:'wrap', marginTop: 20}}>
        <img src={Rome} style={{height: 350, width:350, margin: 10}}/>
        <div style={{margin: 20, width:'50%', minWidth: 350}}>    
        <p style={{fontSize:  isMobile? 20:  25, fontWeight: '600'}}>
          Once the AI has worked its magic, you have the option to bring your masterpiece to life in the physical world. Choose to have your artwork printed on high-quality posters or canvases, ready to adorn your walls and spark conversations. The marriage of cutting-edge technology and traditional artistry results in a truly exceptional piece that you can cherish forever.
        </p>
        <p style={{fontSize:  isMobile? 20:  25, fontWeight: '600'}}>
          Join us on this exhilarating journey where human imagination collaborates with artificial intelligence to craft beauty. Whether you're an art enthusiast, a decorator with a vision, or simply seeking a unique way to express yourself, AIMG is here to turn your dreams into stunning reality.
        </p>
        </div>
        </div>
      </div>
      <div  style={{ padding:   isMobile? 20 : 50}}>
      <p style={{fontSize: isMobile? 30 : 40, fontWeight:'600'}}>Meet the Founders / Brothers</p>
        <motion.div whileHover={{scale: 1.05}} transition={{duration: 2}} style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:'center', background:'#4e5666', borderRadius: 10, flexWrap:"wrap", padding: 30}}>
            <img src={David} style={{width:'20%', minWidth: 100, borderRadius: '100%', margin: 10}}/>
            <div>
            <h3>Santiago Ramirez</h3>
            <p>Co-Founder & Student at UC Berkeley</p>
            {/* <p>Contact: rmz92002@gmail.com</p> */}
            </div>
        </motion.div>
        <motion.div whileHover={{scale: 1.05}} transition={{duration: 2}} style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:'center', background:'#4e5666', borderRadius: 10, marginTop:  50, flexWrap:"wrap", padding: 30}}>
            <img src={Patricio} style={{width:'20%',minWidth: 100, borderRadius: '100%', margin: 10}}/>
            <div >
            <h3>Patricio Ramirez</h3>
            <p>Co-Founder & Alumni at University of Pennsylvania</p>
            {/* <p>Contact: Me la pelan</p> */}
            </div>
        </motion.div>
      </div>
      <Footer/>
    </div>
  )
}
