import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/home/Footer';

export default function Shipping() {
    const isMobile = window.innerWidth <= 768;
    const style = {
        color: 'white',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
      };
  return (
    <div>
        <Navbar isMobile={isMobile}/>
    <div style={style}>
      <h1 style={{display:'flex', justifyContent:'center'}}>AIMG.ART LLC - Shipping Policy</h1>
      <p>Last Updated: 11/28/2023</p>

      <p>Welcome to AIMG.ART! This Shipping Policy outlines the shipping and delivery practices of AIMG.ART LLC ("AIMG.ART," "we," "us," or "our") for products purchased through our online platform.</p>

      <h2>1. Processing Time</h2>
      <p>Orders placed on the AIMG.ART platform undergo processing before shipment. Processing times may vary, but typically range from 1 to 5 business days. During peak seasons or special promotions, processing times may be extended.</p>

      <h2>2. Shipping Methods</h2>
      <p>We offer various shipping methods to accommodate different preferences and delivery timelines. Shipping options and associated costs will be displayed during the checkout process. Available shipping methods may include standard shipping, express shipping, and other options based on your location.</p>

      <h2>3. Shipping Rates</h2>
      <p>Shipping rates are calculated based on the selected shipping method, the destination address, and the weight of the order. The applicable shipping charges will be displayed during the checkout process before you complete your purchase.</p>

      <h2>4. Shipping Destinations</h2>
      <p>AIMG.ART currently ships to locations within [list of countries/regions]. If your country or region is not listed during the checkout process, please contact us for further assistance.</p>

      <h2>5. Customs and Duties</h2>
      <p>For international orders, customs duties, taxes, and other fees may be levied by the destination country. These charges are the responsibility of the customer and are not included in the product or shipping costs. AIMG.ART is not responsible for delays caused by customs processing.</p>

      <h2>6. Shipment Tracking</h2>
      <p>Once your order is shipped, you will receive a shipping confirmation email containing a tracking number. You can use this tracking number to monitor the status and location of your shipment. Please allow up to 24 hours for the tracking information to be updated.</p>

      <h2>7. Delivery Timeframes</h2>
      <p>Delivery times vary based on the selected shipping method and the destination. Standard shipping typically takes 10-14 business days, while express shipping may offer quicker delivery. Please note that delivery times are estimates and not guaranteed, as they may be affected by factors such as weather, customs processing, and carrier delays.</p>

      <h2>8. Delivery Issues</h2>
      <p>If you encounter any issues with the delivery of your order, such as delays, lost packages, or damages, please contact us immediately at aimghelp@gmail.com. We will work with the shipping carrier to resolve the issue and ensure that you receive your order in a timely manner.</p>

      <h2>9. Changes to Shipping Policy</h2>
      <p>AIMG.ART reserves the right to update or modify this Shipping Policy at any time. Any changes will be effective immediately upon posting to the AIMG.ART website.</p>

      <h2>10. Contact Information</h2>
      <p>If you have any questions or concerns about our Shipping Policy, please contact us at aimghelp@gmail.com</p>

      <p>Thank you for choosing AIMG.ART!</p>
    </div>
    <Footer/>
    </div>
  )
}
