import React from 'react'
import Navbar from '../components/Navbar'
import Shrek from '../images/David.jpeg'
import Information from '../components/profile/Information'
import PaintingMarket from '../components/market/PaintingMarket'
import { useNavigate } from 'react-router-dom'
import Gallery from '../components/profile/Gallery'

const PaintingInformation = 
    {
        name: "Shrek as a God",
        price: 1024,
        image: Shrek,
        date: Date.now(),
        artist: {
            name: "Santiago Ramirez",
            image: Shrek
        },
        description: "It is a painting about the god Shrek. It brings the light of the darkness to the light. I will fight and Strive unitl I die.",
        limited: true,
        available: 10,
        total: 50
    }
  const Painting = [
      {
          name: "Shrek as a God",
          price: 1024,
          upordown: "up",
          image: Shrek,
          date: Date.now(),
          artist: {
              name: "Santiago Ramirez",
              image: Shrek
          }
      },
      {
          name: "Shrek as a God",
          price: 1024,
          upordown: "up",
          image: Shrek,
          date: Date.now(),
          artist: {
              name: "Santiago Ramirez",
              image: Shrek
          }
      },
      {
          name: "Shrek as a God",
          price: 1024,
          upordown: "up",
          image: Shrek,
          date: Date.now(),
          artist: {
              name: "Santiago Ramirez",
              image: Shrek
          }
      },
      {
          name: "Shrek as a God",
          price: 1024,
          upordown: "up",
          image: Shrek,
          date: Date.now(),
          artist: {
              name: "Santiago Ramirez",
              image: Shrek
          }
      }
  ]

export default function Profile() {
  const navigate = useNavigate()
  return (
    <div >
        <Navbar/>   
        <Information name={PaintingInformation.name}  profilePic={PaintingInformation.image} description={"Follow my other socias: \n Instagram: @Melapela \n Phone number: +507 344 593"} artworks={[Shrek, Shrek, Shrek]}/>
        <Gallery Painting={Painting} navigate={navigate}/>
    </div>  
  )
}
