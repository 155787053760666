import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/home/Footer';

export default function TermAndConditions() {
    const isMobile  = window.innerWidth <= 768;
    const style = {
        color: 'white',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
      };
  return (
    <div>
        <Navbar isMobile={isMobile}/>
        <div style={style}>
      <h1 style={{display:"flex", justifyContent:'center'}}>AIMG.ART LLC - Terms of Service</h1>
      <p>Last Updated: 11/28/2023</p>

      <p>Welcome to AIMG.ART! These Terms of Service ("Terms") govern your use of the AIMG.ART website (the "Platform") operated by AIMG.ART LLC ("AIMG.ART," "we," "us," or "our"). By accessing or using our Platform, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the Platform.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By using the Platform, you agree to these Terms and our Privacy Policy. We may modify the Terms at any time, and it is your responsibility to review them regularly. Your continued use of the Platform after any modifications indicates your acceptance of the modified Terms.</p>

      <h2>2. Description of Service</h2>
      <p>AIMG.ART is an online platform that allows users to explore, customize, and purchase AI generative art. The Platform offers a range of features, including artwork browsing, customization tools, and an e-commerce platform for art purchase.</p>

      <h2>3. User Accounts</h2>
      <p>To access certain features of the Platform, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

      <h2>4. User Content</h2>
      <p>By using the Platform, you may upload, submit, store, send, or receive content. You retain ownership of any intellectual property rights that you hold in that content. By uploading, submitting, storing, sending, or receiving content through the Platform, you grant AIMG.ART a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, distribute, and display the content solely for the purpose of operating, promoting, and improving the Platform.</p>

      <h2>5. Payment and Pricing</h2>
      <p>When you make a purchase on AIMG.ART, you agree to pay the specified price for the artwork plus any applicable taxes and fees. Payment is processed through secure third-party payment processors. Prices are subject to change without notice.</p>

      <h2>6. Intellectual Property</h2>
      <p>The Platform, including all content, features, and functionality, is the exclusive property of AIMG.ART and its licensors. The Platform is protected by copyright, trademark, and other laws. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Platform without the prior written consent of AIMG.ART.</p>

      <h2>7. Disclaimer of Warranties</h2>
      <p>The Platform is provided "as is" and "as available" without any warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

      <h2>8. Limitation of Liability</h2>
      <p>In no event shall AIMG.ART, its officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Platform; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the Platform; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Platform by any third party; or (e) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the Platform.</p>

      <h2>9. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of the state of Florida, without regard to its conflict of law principles.</p>

      <h2>10. Contact Information</h2>
      <p>If you have any questions or concerns about these Terms, please contact us at aimghelp@gmail.com</p>

      <p>Thank you for using AIMG.ART!</p>
    </div>
    <Footer/>
    </div>
  )
}
