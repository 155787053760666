import React from 'react';
import ReactDOM from 'react-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FaMagic } from "react-icons/fa";
import {motion} from 'framer-motion'
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate, useLocation } from 'react-router-dom'
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#00a2ff'
        },
      secondary: {
        main: '#E33E7F'
      }
    }
  });

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});



const WithMaterialUI = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
const navigate = useNavigate();

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%'}}>
    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', marginTop: 50,}}>
    <FaMagic size={35}/>
    <h1 style={{color:'white', fontSize: 40, fontWeight:'600',  marginLeft: 20}}>AIMG</h1>
    </div>
    <div style={{width: '50%'}}>
    <form onSubmit={formik.handleSubmit}>
    <ThemeProvider theme={theme}>
        <TextField
          fullWidth
        style={{color:'white'}}
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputLabelProps={{style: { color: '#fff' }}}
          InputProps={{ inputProps: { style: { color: '#fff' }}}}
        />
        <TextField
          fullWidth
          style={{color:'white', marginTop: 50, marginBottom:50,  borderColor: "white"}}
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputLabelProps={{style: { color: '#fff' }}}
          InputProps={{ inputProps: { style: { color: '#fff' }}}}
        />
        </ThemeProvider>
        <Button style={{fontWeight:"600", background:'#00a2ff'}} color="primary" variant="contained" fullWidth type="submit">
          Log in
        </Button>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop: 15}}>
        If you don't have an account <a href='signup' style={{color:"#00a2ff", marginLeft: 5}}>click here to create account</a>
        </div>
       
      </form>
    </div>
    <motion.div onTap={()=> navigate('/')} whileTap={{scale: 0.9}} style={{position:'absolute', left: 30, top: 30 }}  >
            <IoIosArrowDropleftCircle size={50}/>
    </motion.div>
    <p style={{fontSize: 20, fontWeight: '600', margin: 30}}>Or</p>
    <motion.span whileTap={{scale: 0.9}} style={{ display:'flex', flexDirection:'row', alignItems:'center', background:'#4e5666', paddingRight: 15, paddingLeft: 15, borderRadius: 10, width: 350}}>
        <FcGoogle size={40}/>
        <p style={{color:'white', fontSize: 22, fontWeight:'600', marginLeft: 15}}>Continue with Google</p>
    </motion.span>
    <motion.span whileTap={{scale: 0.9}} style={{ display:'flex', flexDirection:'row', alignItems:'center', background:'#4e5666', paddingRight: 15, paddingLeft: 15, borderRadius: 10, width: 350, marginTop: 25}}>
        <FaFacebook color='#0081ff' size={40}/>
        <p style={{color:'white', fontSize: 22, fontWeight:'600', marginLeft: 15}}>Continue with Facebook</p>
    </motion.span>
    </div>
  );
};

export default WithMaterialUI;