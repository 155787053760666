import React from 'react'
import { motion } from 'framer-motion'
import { AiOutlinePlusCircle} from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";

function FinishBuy({Quantity, increase, decrease, Price}) {
  return (
    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly',  padding: 35}}>
  
    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', fontSize: 25, fontWeight:'500'}}>
       Quantity
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop: 15, fontWeight:'600'}}>
        
            <motion.div onTap={ Quantity && decrease} style={{display:'flex', marginRight: 15}} whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
             <AiOutlineMinusCircle size={25} color={Quantity? "white": "grey"}/>
             </motion.div>
             {Quantity}
            <motion.div onTap={increase} style={{display:'flex', marginLeft: 15}}  whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
            <AiOutlinePlusCircle size={25} />
            </motion.div>
        
        </div>
    </div>
    <div style={{fontSize: 25, fontWeight: '500', display:'flex', flexDirection:'column', alignItems:'center'}}>
    Total
    <div style={{ marginTop: 15, fontWeight:"600"}}>
    $ {Quantity*Price}
    </div>
   
    </div>
    
    </div>
  )
}

export default FinishBuy
