import React from 'react'
import { motion } from 'framer-motion'

import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";




export default function preview({AttributePreview, image, Quantity, mobile}) {
    
  return (
    <div style={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center', marginTop: 50}}  >
            {
                mobile== true? 
                (<div style={{display:'flex', justifyContent:'center', alignItems:'center', justifySelf:"center"}}><img src={image} style={{position:'absolute', width: AttributePreview.imageWidth*.8, height: AttributePreview.imageHeight*.8, justifySelf:'center', alignItems:'center', marginTop: AttributePreview.marginTop*.8, marginLeft:AttributePreview.marginLeft*.8}}/>
                <img src={AttributePreview.image} style={{borderRadius: 15, width: 300, display:'flex', alignItems:'center'}}/> </div>) : 
            (<div style={{display:'flex', justifyContent:'center', alignItems:'center', justifySelf:"center"}}><img src={image} style={{position:'absolute', width: AttributePreview.imageWidth, height: AttributePreview.imageHeight, justifySelf:'center', alignItems:'center', marginTop: AttributePreview.marginTop, marginLeft:AttributePreview.marginLeft}}/>
            <img src={AttributePreview.image} style={{borderRadius: 15, width: 380}}/></div>) 
            }
    </div>
  )
}
