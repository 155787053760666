import React from 'react'
import { motion } from 'framer-motion'
import Select from 'react-select';


    // const options = [
    //   { value:  "13x18 cm / 5x7" , label: "13x18 cm / 5x7", bgcolor: "#498205", color: "#ffA" },
    //   { value:  "15x20 cm / 6x8" , label: "15x20 cm / 6x8", bgcolor: "#c19c00", color: "#Aff" },
    //   { value: "A4 21x29.7 cm / 8x12", label: "A4 21x29.7 cm / 8x12", bgcolor: "#da3b01", color: "#fAf" },
    //   { value: "28x43  cm / XL (11x17″)", label: "28x43  cm / XL (11x17″)", bgcolor: "#004e8c", color: "#fff" },
    //   { value:  "30x30 cm / 12x12″ " , label: "30x30 cm / 12x12″", bgcolor: "#498205", color: "#ffA" },
    //   { value:  "30x40 cm / 12x16″ " , label: "30x40 cm / 12x16″", bgcolor: "#c19c00", color: "#Aff" },
    //   { value: "30x45 cm / 12x18″ ", label: "30x45 cm / 12x18″", bgcolor: "#da3b01", color: "#fAf" },
    //   { value: "40x50 cm / 16x20″ ", label: "40x50 cm / 16x20″", bgcolor: "#004e8c", color: "#fff" },
    //   { value:  "45x60 cm / 18x24″ " , label: "45x60 cm / 18x24″", bgcolor: "#498205", color: "#ffA" },
    //   { value:  "50x50 cm / 20x20″ " , label: "50x50 cm / 20x20″", bgcolor: "#c19c00", color: "#Aff" },
    //   { value: "60x80 cm / 24x32″ ", label: "60x80 cm / 24x32″", bgcolor: "#da3b01", color: "#fAf" },
    //   { value: "60x90 cm / 24x36″ ", label: "60x90 cm / 24x36″", bgcolor: "#004e8c", color: "#fff" },
    //   { value:  "70x70 cm / 28x28″" , label: "70x70 cm / 28x28″", bgcolor: "#c19c00", color: "#Aff" },
    //   { value: "70x100 cm / 28x40″", label: "70x100 cm / 28x40″", bgcolor: "#da3b01", color: "#fAf" },
    // ];




const styles = {
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isSelected ? "bold" : "normal",
          color: state.isSelected ? "white" : "black",
          fontSize: state.selectProps.myFontSize
        })
    };
    
  
export default function Size({ChangeSize, options}) {
  return (
    <div style={{display:"flex", flexDirection:'column', padding: 40, width:"100%"}}>
        <p style={{fontSize: 30, fontWeight:'500'}}>Size</p>
        <div style={{width:'85%'}}>
        <Select
        options={options} styles={styles} onChange={ChangeSize}/>
        </div>
        
    </div>
  )
}
