import React from 'react'

export default function Information({profilePic, name, description, followers, following, artworks}) {
  return (
    <div style={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}}>
        <img src={profilePic} style={{height: 350, width: 350, borderRadius: 350, marginTop: 40}}/>
        <div style={{fontSize: 45, fontWeight:"600", margin: 10}}>
            {name}
        </div>
        <div style={{fontSize: 20, fontWeight:"500", opacity: 0.9, margin: 10, width: '45%', textAlign:'center'}}>
            {description}
        </div>
    
    </div>

  )
}
