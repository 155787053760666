import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { useScroll } from 'framer-motion';
import Shrek from '../images/David.jpeg'
import Artists from '../components/market/Artists'
import PaintingMarket from '../components/market/PaintingMarket';
import { useNavigate } from 'react-router-dom';


const ArtistsData = [
    {
        name: "Santiago Ramirez",
        image: Shrek
    },
    {
        name: "Alvaro Barroso",
        image: Shrek
    },
    {
        name: "Emilio Rivas",
        image: Shrek
    },
]

const Painting = [
    {
        name: "Shrek as a God",
        price: 1024,
        upordown: "up",
        image: Shrek,
        date: Date.now(),
        artist: {
            name: "Santiago Ramirez",
            image: Shrek
        }
    },
    {
        name: "Shrek as a God",
        price: 1024,
        upordown: "up",
        image: Shrek,
        date: Date.now(),
        artist: {
            name: "Santiago Ramirez",
            image: Shrek
        }
    },
    {
        name: "Shrek as a God",
        price: 1024,
        upordown: "up",
        image: Shrek,
        date: Date.now(),
        artist: {
            name: "Santiago Ramirez",
            image: Shrek
        }
    },
    {
        name: "Shrek as a God",
        price: 1024,
        upordown: "up",
        image: Shrek,
        date: Date.now(),
        artist: {
            name: "Santiago Ramirez",
            image: Shrek
        }
    }
]

export default function Market() {
    const navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
  return (
    <div>
        <Navbar isMobile={isMobile}/>   
        <Artists allArtists={ArtistsData} navigate={navigate}/>
        <PaintingMarket Painting={Painting} navigate={navigate}/>
    </div>
  )
}