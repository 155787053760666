import React from 'react'
import {motion} from 'framer-motion'
export default function Artists({allArtists, navigate}) {
  return (
    <div>
        <p style={{fontSize: 35, fontWeight:'600', marginLeft: 50}}>
            Upcoming Artists
        </p>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginBottom:50, overflowX:'auto', overflowY:'auto'}}>
        {allArtists.map(item=>
            <motion.div whileTap={{scale:0.9}} onTap={()=> navigate('/profile')} style={{display:'flex', flexDirection:'column', alignItems:'center', marginLeft: 40, marginRight: 10}}>
            <img style={{height: 150, width: 150, borderRadius: 100}} src={item.image}/>
            <p style={{fontSize: 20, fontWeight:'500', opacity: 0.9, textAlign:'center'}}>{item.name}</p>
            </motion.div>
            )}
            </div>
    </div>
  )
}
