import React from 'react'
import {motion} from 'framer-motion'

export default function Information({name, artist, date, description, price, limited, available, total}) {

  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'95%', justifyContent:'space-around'}}>
      
      <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'90%', justifyContent:'center'}}>
        <div style={{textAlign:'center', fontSize: 45, fontWeight:'600'}}>{name}</div>
        <div style={{fontSize: 30, fontWeight:'600', background: '#4e5666', padding: 10, borderRadius: 10, marginLeft:40}}>${price}</div>
        </div>
        <div style={{margin: 30}}>
        <div style={{textAlign:'left', fontSize:20, fontWeight:'500', opacity:0.8, textAlign:'initial', marginBottom: 10, }}>Description</div>
        <div style={{textAlign:'left', fontSize:30, fontWeight:'500', opacity:0.8, marginLeft: 10}}>{description}</div>
        </div>
       
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"space-between", width:'90%'}}>
        <motion.span whileTap={{scale: 0.9}} style={{display:'flex', flexDirection:'row', alignItems:'center',  cursor:'pointer'}}>
            <img src={artist.image} style={{height: 50, width: 50, borderRadius: 50}}/>
            <p style={{textAlign:'center', fontSize: 20, fontWeight:'500', opacity: 0.8, marginLeft:10}}>By</p>
            <span style={{textAlign:'center', fontSize: 20, fontWeight:'500', marginLeft: 7}}>{artist.name}</span>
        </motion.span>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}> 
        <p style={{textAlign:'center', fontSize: 20, fontWeight:'500', opacity: 0.9}}>Created in</p>
        <p style={{textAlign:'center', fontSize: 20, fontWeight:'500', marginLeft: 7}}>{date}</p>
        </div>
        </div>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around', width:'100%'}}>
        </div>
          <motion.span whileTap={{scale: 0.9}} style={{height:50, display:'flex', fontSize:30, background:'#cd00ff', borderRadius:10, fontWeight:'600', cursor:'pointer',textAlign:'center', justifyContent:'center', alignItems:'center', width:150, margin: 30}}>
          Buy
         </motion.span>
    </div>
  )
}
