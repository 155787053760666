
import { Routes, Route, useLocation }
    from 'react-router-dom';
import Home from '../pages/Home'
import Products from '../pages/Products';
import Create from '../pages/Create';
import About from '../pages/About';
import Contact from '../pages/Contact';
import ConfirmOrder from '../pages/ConfirmOrder';
import Market from '../pages/Market';
import Painting from '../pages/Painting';
import Profile from '../pages/Profile';
import Login from '../pages/Login';
import Signup from '../pages/Signup'
import Shipping from '../pages/Shipping';
import ReturnAndRefund from '../pages/ReturnAndRefund';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermAndConditions from '../pages/TermAndConditions';


function AnimatedPages() {
    const location = useLocation();
    return (
  
            <Routes location={location} key={location.pathname}>
                <Route exact path='/' exact element={<Home />} />
                <Route exact path='/products' exact element={<Products />} />
                <Route exact path='/create' exact element={<Create />} />
                <Route exact path='/about' exact element={<About />} />
                <Route exact path='/contact' exact element={<Contact />} />
                <Route exact path='/confirm' exact element={<ConfirmOrder />} />
                <Route exact path='/market' exact element={<Market />} />
                <Route exact path='/market/painting' exact element={<Painting />} />
                <Route exact path='/profile' exact element={<Profile />} />
                <Route exact path='/login' exact element={<Login />} />
                <Route exact path='/signup' exact element={<Signup />} />
                <Route exact path='/shipping' exact element={<Shipping />} />
                <Route exact path='/returnandrefund' exact element={<ReturnAndRefund />} />
                <Route exact path='/privacypolicy' exact element={<PrivacyPolicy />} />
                <Route exact path='/termsandconditions' exact element={<TermAndConditions />} />
            </Routes>

    )
}

export default AnimatedPages