import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { useScroll } from 'framer-motion';

export default function ConfirmOrder() {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
  return (
    <div>
        <Navbar isMobile={isMobile}/>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <p style={{fontSize: 40, fontWeight:'600'}}>Thank You for Ordering!</p>
        <p style={{fontSize: 30, width:'70%', textAlign:'center'}}>We appreciate your business. Your order will aproximately arrive in 5-14 business days. If you have any questions or concerns regarding your order, please don't hesitate to contact us:</p>
            <p style={{fontSize: 20, width:'70%', textAlign:'center'}}>Email: <a style={{color:'white'}} href="mailto:aimghelp@gmail.com">aimghelp@gmail.com</a></p>
        </div>
    </div>
  )
}
