// Footer.js
import React from 'react';
import styled from 'styled-components';
import { BsFacebook, BsInstagram, BsTiktok } from "react-icons/bs"


const FooterContainer = styled.footer`
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 20px 0;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterLogo = styled.div`
  font-size: 24px;
  font-weight: bold;

  a {
    text-decoration: none;
    color: #ffffff;
  }
  
  a:hover {
    text-decoration: underline;
  }
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 10px 0;
  }

  a {
    text-decoration: none;
    color: #ffffff;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const FooterSocial = styled.div`
  font-size: 24px;

  a {
    text-decoration: none;
    color: #ffffff;
    margin: 0 10px;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <FooterContainer style={{padding: 20}}>
      <FooterContent>
        <FooterLogo style={{display:'flex', flexDirection:'column', justifyContent:'space-between', height: '100%'}}>
          <a href="#">AIMG</a>
          <p style={{fontSize:14, fontWeight:'400'}}>@2023, AIMG created by Santiago and Patricio</p>
        </FooterLogo>
        {/* <FooterLinks>
        <p style={{fontWeight:'600'}}>Pages</p>
          <li><a href="#">Home</a></li>
          <li><a href="create">Create</a></li>
          <li><a href="about">About</a></li>
          <li><a href="contact">Contact</a></li>
        </FooterLinks> */}
        <FooterLogo style={{display:'flex', flexDirection:'column', justifyContent:'space-between', height: '100%'}}>
          <a>Information</a>
          <a href="privacypolicy" style={{fontSize:14, fontWeight:'400', paddingTop: 10}}>Privacy Policy</a>
          <a href="returnandrefund" style={{fontSize:14, fontWeight:'400',  paddingTop: 10}}>Return and Refund</a>
          <a href="shipping" style={{fontSize:14, fontWeight:'400',  paddingTop: 10}}>Shipping Policy</a>
          <a href="termsandconditions" style={{fontSize:14, fontWeight:'400',  paddingTop: 10}}>Terms and Conditions</a>
        </FooterLogo>
        <FooterLogo style={{display:'flex', flexDirection:'column', justifyContent:'space-between', height: '100%'}} >
        <p style={{fontWeight:'600'}}>Socials</p>
        <div style={{display:'flex', flexDirection: 'row', flexWrap:'wrap', alignItems:'center', justifyContent:'center'}}>
        {/* <a href="https://www.facebook.com" style={{margin: 7}}><i className="fab fa-facebook">
            <BsFacebook size={20}/>
            </i></a> */}
          <a href="https://www.tiktok.com/@aimg.art" style={{margin: 7}}><i className="fab fa-tiktok">
         <BsTiktok size={20}/></i></a>
          <a href="https://www.instagram.com/aimg.art/?hl=es" style={{margin: 7}}><i className="fab fa-instagram">
          <BsInstagram size={20}/>
            </i></a>
        </div>
        </FooterLogo>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
