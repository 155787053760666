import React from 'react'
import Navbar from '../components/Navbar'
import Shrek from '../images/David.jpeg'
import Information from '../components/Painting/Information'
import { format } from 'date-fns';
import FinishBuy from '../components/create/FinishBuy';
import { motion } from 'framer-motion';

const PaintingInformation = 
    {
        name: "Shrek as a God",
        price: 1024,
        image: Shrek,
        date: Date.now(),
        artist: {
            name: "Santiago Ramirez",
            image: Shrek
        },
        description: "It is a painting about the god Shrek. It brings the light of the darkness to the light. I will fight and Strive unitl I die.",
        limited: true,
        available: 10,
        total: 50
    }

export default function Painting() {
  const [Quantity, ChangeQuantity] = React.useState(1)
  const [Price, changePrice] = React.useState(30)
  const [RealSize,ChangeSize] = React.useState({ value:  "50x50" , label: "50x50 cm / 20x20″ Medium", bgcolor: "#c19c00", color: "#Aff" })
  const increase = () => {
    ChangeQuantity(Quantity+1)
}
const decrease = ()=> {
    ChangeQuantity(Quantity-1)
}
  return (
    <div style={{height:'100%'}}>
        <Navbar/>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop: 50, marginBottom: 50, height:'100%', justifyContent:"space-around", width:'100%'}}>
        <div style={{height: '100%', width:'50%', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <div style={{background:'#4e5666', padding: 20, borderRadius: 10}}>
        <img src={Shrek} style={{height: 500, width: 500}}/>
        </div>    
        </div>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'60%', height:'100%'}}>
        <Information limited={PaintingInformation.limited} available={PaintingInformation.available} total={PaintingInformation.total} name={PaintingInformation.name} price={PaintingInformation.price} date={format(PaintingInformation.date, 'MMM dd yyyy')} description={PaintingInformation.description} artist={PaintingInformation.artist}/>
        </div>
        </div>
    </div>
  )
}
