import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion';
import Search from '../components/create/Search';
import Size from '../components/create/Size';
import Images from '../components/create/Images';
import client from '../api/client';
import Preview from '../components/create/preview';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import FinishBuy from '../components/create/FinishBuy';
import Poster30 from '../images/Poster/30x30Poster.jpeg'
import Poster40 from '../images/Poster/40x40Poster.jpeg'
import Poster50 from '../images/Poster/50x50Poster.jpeg'
import Poster70 from '../images/Poster/70x70Poster.jpeg'
import { TailSpin } from  'react-loader-spinner'
import Style from '../components/create/Style';
import Canvas20 from '../images/Canvas/20x20Canvas.jpeg'
import Canvas30 from '../images/Canvas/30x30Canvas.jpeg'
import Canvas40 from '../images/Canvas/40x40Canvas.jpeg'
import Canvas50 from '../images/Canvas/50x50Canvas.jpeg'
import Canvas60 from '../images/Canvas/60x60Canvas.jpeg'



const Poster = [
    {
        name: "30x30",
        image: Poster30,
        imageWidth: 66,
    imageHeight: 66,
    marginTop: -15, 
    marginLeft: -18,
    value:  "30x30" , 
    label: "30x30 cm / 12x12″",
    price: 11.99
    },
    {
        name: "40x40",
        image: Poster40,
        imageWidth: 87,
        imageHeight: 87,
        marginTop: -40, 
        marginLeft: 5,
        value:  "40x40" , 
        label: "40x40 cm / 16x16″",
        price: 19.99
    },
    {
        name: "50x50",
        image: Poster50,
        imageWidth: 109,
    imageHeight: 109,
    marginTop: -65, 
    marginLeft: 31,
    value:  "50x50" , 
    label: "50x50 cm / 20x20″",
    price: 23.99
    },
    {
        name: "60x60",
        image: Poster50,
        imageWidth: 131,
    imageHeight: 131,
    marginTop: -80, 
    marginLeft: 31,
    value:  "60x60" , 
    label: "60x60 cm / 24x24″",
    price: 29.99
    },
    {
    name: "70x70",
    image: Poster70,
    imageWidth: 152,
    imageHeight: 152,
    marginTop: -113, 
    marginLeft: 80,
    value:  "70x70" , 
    label: "70x70 cm / 28x28″",
    price: 34.99
    },
    {
        name: "100x100",
        image: Poster70,
        imageWidth: 217,
        imageHeight: 217,
        marginTop: -163, 
        marginLeft: 80,
        value:  "100x100" , 
        label: "100x100 cm / 40x40″",
        price: 49.99
        },
]
const Canvas = [
    {
        name : "20x20",
        image: Canvas20,
        imageWidth: 45,
        imageHeight: 45,
        marginTop: 10, 
        marginLeft:-45,
        value:  "20x20" , 
        label: "20x20 cm / 8x8″",
        price : 14.99
    },
    {
        name : "30x30",
        image: Canvas30,
        imageWidth: 67,
        imageHeight: 67,
        marginTop: -13, 
        marginLeft: -20,
        value:  "30x30" , 
        label: "30x30 cm / 12x12″",
        price : 19.99
    },
    {
        name : "40x40",
        image: Canvas40,
        imageWidth: 90,
        imageHeight: 90,
        marginTop: -38, 
        marginLeft: 5,
        value:  "40x40" , 
        label: "40x40 cm / 16x16″",
        price : 24.99
    },
    {
        name : "50x50",
        image: Canvas50,
        imageWidth: 110,
        imageHeight: 110,
        marginTop: -60, 
        marginLeft: 26,
        value:  "50x50" , 
        label: "50x50 cm / 20x20″",
        price : 34.99
    },
    {
        name : "60x60",
        image: Canvas60,
        imageWidth: 132,
        imageHeight: 132,
        marginTop: -85, 
        marginLeft: 50,
        value:  "60x60" , 
        label: "60x60 cm / 24x24″",
        price : 39.99
    }
]

// const Framed = [{
//     name : "30x30",
//     image: thirty,
//     imageWidth: 59,
//     imageHeight: 59,
//     marginTop: -16, 
//     marginLeft: -19,
//     value:  "30x30" , 
//     label: "30x30 cm / 12x12″ Small",
//     price : 24.99
    
// },
// {
//     name : "50x50",
//     image: fifty,
//     imageWidth: 102,
//     imageHeight: 102,
//     marginTop: -65, 
//     marginLeft: 30,
//     value:  "50x50" , 
//     label: "50x50 cm / 20x20″ Medium",
//     price : 49.99
// },
// {
//     name : "70x70",
//     image: seventy,
//     imageWidth: 146,
//     imageHeight: 146,
//     marginTop: -112, 
//     marginLeft: 77,
//     value:  "70x70" , 
//     label: "70x70 cm / 28x28″ Large",
//     price : 79.99
// }
// ]


export default function Create() {
   
    const [AllImages, SetImages] = React.useState(null)
    const [SearchWord, ChangeSearch] = React.useState("")
    const [SelectImage, ChangeSelection] = React.useState()
    const [Quantity, ChangeQuantity] = React.useState(1)
    const [Price, changePrice] = React.useState(30)
    const [RealSize,ChangeSize] = React.useState({ value:  "50x50" , label: "50x50 cm / 20x20″", bgcolor: "#c19c00", color: "#Aff" })
    const [allPreviews, ChangeAllPreviews] = React.useState()
    const [loading, changeLoading] = React.useState(false)
    const [loadingScreen, changeLoadingScreen] = React.useState(false)
    const [ StyleSelected, ChangeStyle] = React.useState("None")
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const style = queryParams.get('style');
    const [width, setWidth] = useState(window.innerWidth);
    const height = window.innerHeight


    useEffect(()=> {
        if (style=="canvas") {
            ChangeAllPreviews(Canvas)
            ChangeSize(Canvas[4])
        }
        else if (style=="poster") {
            ChangeAllPreviews(Poster)
            ChangeSize(Poster[2])
        }
        else {
            ChangeAllPreviews(Poster)
            ChangeSize(Poster[2])
        }
        
        // if(style=="wooden framed" || style == "metal framed") {
        //     ChangeAllPreviews(Framed)
        //     ChangeSize(Framed[2])
        // }
    }, [])
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
   
    

    const increase = () => {
        ChangeQuantity(Quantity+1)
    }
    const decrease = ()=> {
        ChangeQuantity(Quantity-1)
    }
    
    
    const handleClick = async () => {
        changeLoading(true)
        // console.log(RealSize)
        const prompt = StyleSelected == "None"?  SearchWord : `${StyleSelected} style ${SearchWord}`
        const response = await client.post('/getimage', {
            prompt: prompt
        })
        changeLoading(false)
        console.log(response.data)
        ChangeSelection(response.data)

        // console.log(response.data)
    };
    const handleChange = (event) => {
        // 👇 Get input value from "event" in the search bar
        ChangeSearch(event.target.value);

    };

    const BuyNow = async () => {
        // console.log(SelectImage.length)
        changeLoadingScreen(true)
        const res = await client.post('/checkout', {
            name: "canvas", 
            image: SelectImage,
            description: `${RealSize.label}`,
            size: RealSize.name,
            price: Price*100,
            quantity: Quantity
        })
        if (res.data.url) {
            window.location.href = res.data.url
        }
    }

  return (
    <div style={{display:'flex', flexDirection:'row',justifyContent:'normal', flexWrap:'wrap', minHeight:height,  overflowX: isMobile? 'hidden' : null}}>
        {loadingScreen? (<div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: 40, width:'100%'}}>
            
            <TailSpin
  height="150"
  width="150"
  color="white"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
/>
</div>) :(<>
            <div style={{width: isMobile? '100%': '50%', minWidth: 400}}>
        <motion.div whileTap={{scale: 0.9}} style={{marginBottom: 20, width: 50, marginTop: 20, marginLeft: 20}} onTap={()=> navigate("/")} >
            <IoIosArrowDropleftCircle size={50}/>
        </motion.div>
        <Search  SearchWord={SearchWord} loading={loading}  ChangeSearch={handleChange} handleClick={handleClick}/>
        <Style ChangeStyle={ChangeStyle} StyleSelected={StyleSelected}/>
        <div>    
        </div>
        {!loading ? ( SelectImage && <motion.div initial={{opacity: 0, x: -10}} animate={{opacity: 1, x: 0}}> <Images handleClick={handleClick} AllImages={AllImages} SelectImage={SelectImage} ChangeSelection={ChangeSelection}/>
        </motion.div>): <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: 40}}>
            <TailSpin
  height="80"
  width="80"
  color="white"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{}}
  wrapperClass=""
  visible={loading}
/>
</div>}
        {(isMobile && SelectImage)  &&  <> 
        <p style={{fontSize: 30, fontWeight:'500', marginLeft: 40}}>Preview</p>
        <Preview mobile={isMobile} AttributePreview={RealSize} image={SelectImage} Quantity={Quantity} ChangeQuantity={ChangeQuantity}/> </>}
        {SelectImage && (<><Size RealSize={RealSize} ChangeSize={ChangeSize} options={allPreviews} />
        <FinishBuy Quantity={Quantity} increase={increase} decrease={decrease} Price={RealSize.price}/>
       
        <motion.div initial={{opacity:0, y:-10}} animate={{opacity: (SelectImage && Price)? 1: 0.3, y: 0}} onTap={BuyNow}  style={{backgroundColor:'#0082ff', borderRadius: 10, display:'flex', justifyContent:'center', alignItems:'center',  margin: 30, height: 50, paddingRight: 20, paddingLeft: 20, marginRight: 70, marginLeft: 70}} whileHover={{scale:1.1}} whileTap={{scale: 0.9}}>
            <p style={{fontSize: 22, fontWeight: '700'}}>Buy Now</p>
        </motion.div> </>)}
        </div>
        {!isMobile  &&   <div style={{width:'50%', minWidth: 400, background:'white', display:'flex',  height:height, alignItems:'center', flexDirection:'column', justifyContent:'center', position:'sticky', top: 0}} > <Preview mobile={isMobile} AttributePreview={RealSize} image={SelectImage} Quantity={Quantity} ChangeQuantity={ChangeQuantity}/>   </div>}
        </>)}
       
    </div>
  )
}
