import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/home/Footer';

export default function PrivacyPolicy() {
    const isMobile = window.innerWidth <= 768;
    const style = {
        color: 'white',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
      };

  return (
    <div>
 <Navbar isMobile={isMobile}/>
    <div style={style}>
      <h1 style={{display:"flex", justifyContent:'center'}}>AIMG.ART LLC - Privacy Policy</h1>
      <p>Last Updated: 11/28/2023</p>

      <p>AIMG.ART LLC ("AIMG.ART," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use the AIMG.ART website (the "Platform"). By using the Platform, you consent to the practices described in this Privacy Policy.</p>

      <h2>1. Information We Collect</h2>
      <p>We collect information that you provide directly to us, such as when you create an account, customize artwork, make a purchase, or contact us for support. The types of information we may collect include:</p>
      <ul>
        <li>Personal Information: Name, email address, billing address, and other contact information.</li>
        <li>Account Information: Usernames, passwords, and other credentials used to access your AIMG.ART account.</li>
        <li>Transaction Information: Details about purchases you make on the Platform.</li>
        <li>Customization Information: Preferences, settings, and other information you provide when customizing AI generative art.</li>
      </ul>
      <p>We may also collect information automatically through cookies and similar technologies when you use the Platform. This information may include IP addresses, device information, and browsing activity.</p>

      <h2>2. Use of Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>Providing and maintaining the Platform.</li>
        <li>Customizing AI generative art based on your preferences.</li>
        <li>Processing transactions and sending transaction confirmations.</li>
        <li>Responding to your inquiries and providing customer support.</li>
        <li>Sending promotional and marketing communications.</li>
        <li>Improving the functionality and user experience of the Platform.</li>
        <li>Detecting and preventing fraud and ensuring the security of our Platform.</li>
      </ul>

      <h2>3. Sharing of Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy. We may share your information with:</p>
      <ul>
        <li>Service Providers: Third-party service providers who help us operate the Platform, process transactions, or provide other services.</li>
        <li>Legal Compliance: To comply with applicable laws, regulations, legal processes, or government requests.</li>
        <li>Protection of Rights: To protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others.</li>
      </ul>

      <h2>4. Security</h2>
      <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

      <h2>5. Your Choices</h2>
      <p>You can manage your account settings and preferences through the Platform. You may also unsubscribe from promotional communications by following the instructions in the emails we send. Please note that even if you opt-out of receiving marketing emails, we may still send you transactional and administrative emails.</p>

      <h2>6. Children's Privacy</h2>
      <p>The Platform is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take steps to delete such information.</p>

      <h2>7. Changes to this Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. The date of the most recent revision will be indicated at the top of this page. We encourage you to review this Privacy Policy periodically for any changes.</p>

      <h2>8. Contact Information</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at aimghelp@gmail.com</p>

      <p>Thank you for trusting AIMG.ART with your information!</p>
    </div>
    <Footer/>
    </div>

  )
}
